<i18n>
{
  "en": {
    "title": "Pipelines",
    "search": "Search for card name, product, tags...",
    "create": "New card",
    "create-mobile": "New",
    "filter": "Filter",
    "export-results": "Export results",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "reload": "Reload",
    "export": "Export",
    "export-all": "Export all",
    "export-messages": "Export messages logs",
    "import": "Import",
    "welcome-title": "Welcome to wepipe",
    "welcome-description": "We noticed that you don't have any pipeline yet. To start creating cards you need to set up your fisrt pipeline, ok?",
    "welcome-action": "Click here to create",
    "drop-here": "Drop here to move to",
    "nested-item-1": "Duplicate",
    "nested-item-2": "Complete",
    "nested-item-3": "Lost",
    "lost": "Lost"
  },
  "pt-BR": {
    "title": "Pipelines",
    "search": "Pesquise pelo nome, produto, etiqueta...",
    "create": "Novo card",
    "create-mobile": "Nova",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "reload": "Recarregar",
    "export": "Exportar",
    "export-all": "Exportar tudo",
    "export-messages": "Exportar logs de mensagens",
    "import": "Importar",
    "welcome-title": "Bem vindo à wepipe",
    "welcome-description": "Como sua conta é nova, você ainda não possui nenhum pipeline. Para começar a cadastrar os cards você precisa configurar seu primeiro pipeline, tudo bem?",
    "welcome-action": "Clique aqui para criar",
    "drop-here": "Solte aqui para mover para",
    "nested-item-1": "Duplicar",
    "nested-item-2": "Finalizar",
    "nested-item-3": "Abandonar",
    "lost": "Abandonado"
  }
}
</i18n>

<template>
  <div id="pipeline" ref="pipeline">
    <nav-top :title="$t('title')" :hide-mobile-header="true">
      <template #top-content>
        <we-input
          type="search"
          cy="search-in-pipelines"
          v-model="search"
          @input="searchDeals"
          :inputLabel="$t('search')"
        ></we-input>
        <we-button
          class="mobile-icon only-mobile"
          :class="filterActive ? 'primary-light' : 'disabled'"
          :text="$t('filter')"
          icon="sliders-h"
          @click="$router.push({ name: 'filterDealInPipeline' })"
        />
        <we-button
          cy="home-create-deal-in-pipelines"
          class="success mobile-icon"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="plus"
          @click="newDeal"
        />
      </template>
      <template #menu>
        <we-nav-x
          v-if="navigationItems && navigationItems.length > 0"
          ref="weNavX"
          :items="navigationItems"
          :activeIndex="currentPipelineIndex"
          @select="selectPipeline"
          cy="pipeline-tab"
        />
      </template>
    </nav-top>
    <div
      class="board"
      :class="{ 'not-found': !loadColumns && (!stages || stages.length === 0) }"
    >
      <div class="functions" v-if="pipelines.length > 0">
        <div class="filters">
          <div class="items">
            <we-user-selector
              ref="userSelector"
              :disabled="loadingColumns.length > 0"
              @selectUser="filterDealsByUser"
              :selectedPipeline="pipeline"
              cy="pipelines-by-user"
            />
            <we-button
              :class="filterActive ? 'primary-light' : 'disabled'"
              :text="$t('filter')"
              icon="sliders-h"
              @click="$router.push({ name: 'filterDealInPipeline' })"
              cy="filter-in-pipelines"
            />
            <we-balloon v-if="filterActive">
              <template #action>
                <we-icon-button
                  icon="download"
                  :name="$t('export-results')"
                  color="icon-primary"
                />
              </template>
              <template #balloon>
                <div class="balloon-item" @click="exportFilteredFile('csv')">
                  <font-awesome-icon icon="file-csv" />
                  <div class="text">
                    {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
                  </div>
                </div>
              </template>
            </we-balloon>
            <we-icon-button
              v-if="filterActive && pipelineResults > 0"
              :icon="['far', 'trash-alt']"
              :name="$t('delete-filtered')"
              color="icon-red"
              @click="confirmDeleteOpened = true"
            />
            <confirm-modal
              :open="confirmDeleteOpened"
              :total-records="pipelineResults"
              @confirm="deleteFilteredData()"
              @close="confirmDeleteOpened = false"
            />
          </div>
        </div>
        <div class="actions">
          <we-icon-button
            icon="sync-alt"
            :name="$t('reload')"
            @click="reloadDeals"
            cy="reload-pipelines"
          />
          <we-balloon>
            <template #action>
              <we-icon-button
                icon="download"
                :name="$t('export')"
                cy="export-pipelines-trigger"
              />
            </template>
            <template #balloon>
              <div class="balloon-item" @click="exportFile('csv')">
                <font-awesome-icon icon="file-csv" />
                <div class="text" cy="export-pipelines">
                  {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
                </div>
              </div>
              <div class="balloon-item" @click="exportMessages">
                <font-awesome-icon icon="comments" />
                <div class="text">
                  {{ $t("export-messages") }}
                </div>
              </div>
            </template>
          </we-balloon>
          <we-icon-button
            icon="file-upload"
            @click="openImport"
            :name="$t('import')"
            cy="import-deals"
          />
        </div>
      </div>
      <div class="board-content x-scrollbar">
        <we-not-found v-if="!loadColumns && (!stages || stages.length === 0)">
          <template #title> {{ $t("welcome-title") }} </template>
          <template #description>
            {{ $t("welcome-description") }}
            <router-link
              :to="{ name: 'pipelinesConfigs' }"
              class="green--text"
              active-class="active"
            >
              {{ $t("welcome-action") }}
            </router-link>
          </template>
          <template #picture>
            <img
              src="@/assets/undraw/onboarding.svg"
              alt="Onboarding"
              width="299"
              height="254"
            />
          </template>
        </we-not-found>
        <div
          class="pipeline"
          v-if="stages && stages.length > 0"
          orientation="horizontal"
        >
          <div class="overflows" :class="{ show: showOverflows }">
            <div class="overflow" v-for="stage in stages" :key="stage.id">
              {{ $t("drop-here") }}
              <div class="bold">
                {{ stage.index === 9999 ? $t("lost") : stage.name }}
              </div>
            </div>
          </div>
          <div class="loadings">
            <we-loading-overflow
              v-for="(stage, i) in stages"
              :key="stage.id"
              :loading="true"
              :class="{ show: isColumnLoading(i) }"
            />
          </div>
          <we-pipeline-column
            :class="{ disable: showOverflows }"
            v-for="(stage, i) in stages"
            :key="stage.id"
            ref="wePipelineColumnComponent"
            :index="i"
            :stage="stage"
            :pipeline="pipeline"
            :parentLoading="loadColumns"
            @dragOver="showOverflows = $event"
            @addLoading="addLoadingColumn"
            @removeLoading="removeLoadingColumn"
            @dealRightClick="showOptions"
          />
          <we-nested-menu
            name="New Menu"
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            :absolute="true"
            :offset-x="true"
            :menu-items="cardActions"
            @we-nested-menu-click="onMenuItemClick"
          />
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import WePipelineColumn from "@/components/ui-local/WePipelineColumn.vue";
export default {
  components: {
    WePipelineColumn
  },
  data() {
    return {
      confirmDeleteOpened: false,
      createValid: false,
      search: "",
      value: [],
      loadingColumns: [],
      loadingBtn: false,
      loadColumns: true,
      showOverflows: false,
      showMenu: false,
      selectedDeal: {},
      x: 0,
      y: 0
    };
  },
  computed: {
    cardActions() {
      return [
        {
          name: this.$t("nested-item-1"),
          icon: ["far", "copy"],
          action: () => {
            this.duplicateDeal();
          }
        },
        {
          name: this.$t("nested-item-2"),
          icon: "check-circle",
          class: "success--text",
          action: () => {
            this.convertDeal();
          }
        },
        {
          name: this.$t("nested-item-3"),
          icon: "ban",
          class: "red--text",
          menu: this.abandonList,
          action: () => {
            this.abandonDeal("");
          }
        }
      ];
    },
    abandonList() {
      if (
        !this.pipeline?.lost_reason_options ||
        this.pipeline?.lost_reason_options.length <= 0
      )
        return [];

      return this.pipeline.lost_reason_options.map(e => {
        return {
          name: e,
          action: () => {
            this.abandonDeal(e);
          }
        };
      });
    },
    navigationItems() {
      return this.pipelines.map(e => {
        return {
          id: e.id,
          name: e.name,
          number: e.deals
        };
      });
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    usersIdSelected() {
      return this.$store.getters.getUsersIdSelected;
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    pipelines() {
      return this.loggedUser?.pipelines?.length > 0
        ? this.$store.getters.getPipelines.filter(e =>
            this.loggedUser?.pipelines?.includes(e.id)
          )
        : this.$store.getters.getPipelines;
    },
    currentPipelineIndex() {
      return this.$store.getters.getCurrentPipelineIndex;
    },
    pipeline() {
      return this.pipelines[this.currentPipelineIndex];
    },
    stages() {
      return this.pipeline?.stages;
    },
    contactsPages() {
      return this.$store.getters.getContactsPages;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    pipelineResults() {
      return this.$store.getters.getPipelineFilterResults;
    }
  },
  methods: {
    ...mapActions([
      "pipelinesRequest",
      "createContactRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "createDealRequest",
      "updateDealRequest",
      "tagsRequest",
      "deleteFilteredRecords",
      "exportLogMessages"
    ]),
    openImport() {
      this.$router.push({ name: "importDeals" });
    },
    onMenuItemClick(item) {
      if (item.action) {
        item.action();
      }
    },
    async deleteFilteredData() {
      this.$store.commit("setCurrentFilterSave", {});
      this.$store.commit("resetFilters");
      this.$store.commit("setStageDealsQuery", null);
      await this.deleteFilteredRecords();
      await this.reloadDeals();
    },
    async duplicateDeal() {
      const dealColumn = this.$refs.wePipelineColumnComponent.find(
        column => this.selectedDeal.pipeline_stage_id === column.stage.id
      );

      if (!dealColumn) return;

      dealColumn.addLoadingColumn();
      await this.createDealRequest(this.selectedDeal);
      dealColumn.doRequests();
    },
    async convertDeal() {
      const dealColumn = this.$refs.wePipelineColumnComponent.find(
        column => this.selectedDeal.pipeline_stage_id === column.stage.id
      );
      if (!dealColumn) return;
      dealColumn.addLoadingColumn();

      const dealPipeline = this.pipelines.find(
        e => e.id === this.selectedDeal.pipeline_id
      );

      const stages = dealPipeline.stages;
      const winStage = stages.find(e => e.is_the_win_stage);
      const oldStageId = this.selectedDeal.pipeline_stage_id;

      this.selectedDeal.pipeline_stage_id = winStage.id;

      await this.updateDealRequest(this.selectedDeal);

      this.$refs.wePipelineColumnComponent.forEach(column => {
        if ([oldStageId, winStage.id].includes(column.stage.id)) {
          column.doRequests();
        }
      });
    },
    async abandonDeal(reason) {
      const dealColumn = this.$refs.wePipelineColumnComponent.find(
        column => this.selectedDeal.pipeline_stage_id === column.stage.id
      );
      if (!dealColumn) return;
      dealColumn.addLoadingColumn();

      const dealPipeline = this.pipelines.find(
        e => e.id === this.selectedDeal.pipeline_id
      );

      const stages = dealPipeline.stages;
      const abandonStage = stages[stages.length - 1];
      const oldStageId = this.selectedDeal.pipeline_stage_id;
      this.selectedDeal.lost_reason = reason;

      this.selectedDeal.pipeline_stage_id = abandonStage.id;

      await this.updateDealRequest(this.selectedDeal);

      this.$refs.wePipelineColumnComponent.forEach(column => {
        if ([oldStageId, abandonStage.id].includes(column.stage.id)) {
          column.doRequests();
        }
      });
    },
    showOptions({ event, deal }) {
      this.selectedDeal = { ...deal };

      if (this.selectedDeal?.companies?.length > 0) {
        this.selectedDeal.companies = this.selectedDeal.companies.map(
          company => company.pivot.company_id
        );
      }

      if (this.selectedDeal?.contacts?.length > 0) {
        this.selectedDeal.contacts = this.selectedDeal.contacts.map(
          contact => contact.pivot.contact_id
        );
      }

      if (this.selectedDeal?.products?.length > 0) {
        this.selectedDeal.products = this.selectedDeal.products.map(product => {
          return {
            id: product.pivot.product_id,
            amount: product.amount || 1
          };
        });
      }

      delete this.selectedDeal.user;
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    addLoadingColumn(evt) {
      if (!this.loadingColumns.includes(evt)) {
        this.loadingColumns.push(evt);
      }
    },
    removeLoadingColumn(evt) {
      this.loadingColumns = this.loadingColumns.filter(e => e != evt);
    },
    isColumnLoading(i) {
      return this.loadingColumns.includes(i) || this.loadColumns;
    },
    exportFile() {
      const payload = {
        entity: "deals"
      };

      this.exportRequest(payload);
    },
    exportMessages() {
      this.exportLogMessages();
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("deals-filtered");
      }
    },
    async filterDealsByUser() {
      this.$store.commit("setFilterActive", false);
      this.loadColumns = true;

      const query = {
        endpoint: "/search",
        payload: {
          paginate: true
        }
      };

      let hasSearchOrUser = false;

      query.payload.value = this.search.length > 0 ? this.search : "%";

      if (this.search.length > 0) {
        hasSearchOrUser = true;
      }

      if (this.usersIdSelected.length > 0) {
        query.payload.users = this.usersIdSelected.join(",");
        hasSearchOrUser = true;
      }

      if (hasSearchOrUser) {
        this.$store.commit("setStageDealsQuery", query);
      } else {
        this.$store.commit("setStageDealsQuery", null);
      }

      await this.reloadDeals();

      this.loadColumns = false;
    },
    searchDeals(val) {
      this.$store.commit("setFilterActive", false);
      this.loadColumns = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const query = {
          endpoint: "/search",
          payload: {
            paginate: true
          }
        };
        if (this.usersIdSelected.length > 0) {
          query.payload.users = this.usersIdSelected.join(",");
        }
        if (val?.length > 0) {
          query.payload.value = val;
        } else {
          query.payload.value = "%";
        }
        this.$store.commit("setStageDealsQuery", query);
        await this.reloadDeals();
        this.loadColumns = false;
      }, 600);
    },
    newDeal() {
      this.$router.push({ name: "createDealInPipelines" });
    },
    async doRequests() {
      this.$store.commit("setFilterActive", false);
      this.tagsRequest();
      this.loadColumns = false;
    },
    async createContact() {
      this.loadingBtn = true;
      const { first_name, last_name, email } = this;
      await this.createContactRequest({ first_name, last_name, email });
      this.loadingBtn = false;
    },
    selectPipeline(index) {
      this.search = "";
      this.$store.commit("resetUsersSelected");
      this.$store.commit("setStageDealsQuery", null);
      this.$store.commit("setFilterActive", false);
      this.$store.commit("setCurrentPipelineIndex", index);
    },
    reloadDeals() {
      this.doRequests();
      this.$refs.wePipelineColumnComponent?.forEach(e => {
        e.doRequests();
      });
      this.$store.commit("setFilterActive", false);
    },
    addOverflow(event) {
      this.dragoveredIndexes.push(event);
    },
    removeOverflow(event) {
      this.dragoveredIndexes.splice(event, 1);
    }
  },
  created() {
    this.$store.commit("setFilterActive", false);
    this.$store.commit("setStageDealsQuery", null);
  },
  async mounted() {
    const response = await this.pipelinesRequest();
    if (response?.data?.length <= 0) {
      this.$store.commit("setOnboardingOpened", true);
    }
    await this.doRequests();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "pipelines") {
      if (from.name.includes("filterDealInPipeline")) {
        this.reloadDeals();
        this.search = "";
      }
    }
    if (to.query.reload === true) {
      this.reloadDeals();
    }
    if (to.query.fullReload === true) {
      this.pipelinesRequest();
      this.reloadDeals();
    }
    next();
  }
};
</script>

<style lang="scss">
#pipeline {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;

  @include mobile {
    display: block;
  }

  .board {
    flex: 1;
    min-height: 0;
    background: var(--background-2);
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;

    @include mobile {
      height: calc(100% - 158px);
      grid-template-rows: 1fr;
    }

    &.not-found {
      grid-template-rows: 1fr;
    }

    .functions {
      flex-shrink: 0;
      padding: 13px 50px;
      border-bottom: 1px solid var(--line);
      display: flex;
      justify-content: space-between;

      @include mobile {
        display: none;
      }

      .filters {
        .items {
          display: flex;
          align-items: center;
        }

        .we-button {
          margin-right: 8px;
        }

        .filter-results {
          margin-left: 8px;
          font-size: 14px;
          font-weight: $medium;
          color: var(--text-2);
        }
      }

      .actions {
        display: flex;
        align-items: flex-end;

        .item {
          height: 27px;
          padding: 0 9px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: $semibold;
          color: var(--text-2);
          border-radius: 5px;
          margin-left: 8px;
          cursor: pointer;

          &.highlight {
            color: var(--blue);
          }

          &:hover {
            background: var(--action);
          }

          svg {
            height: 16px;
            width: auto;
            margin-right: 7px;
          }
        }
      }
    }

    .board-content {
      overflow-y: hidden;
      height: 100%;
      position: relative;
      padding-left: 50px;
      padding-right: 50px;
      flex: 1;
      min-height: 0;

      @include mobile {
        padding: 0 15px;
      }

      @include desktop {
        &::-webkit-scrollbar {
          height: 14px;
        }
      }

      .pipeline {
        display: inline-flex;
        position: relative;
        height: 100%;

        .overflows,
        .loadings {
          position: sticky;
          pointer-events: none;
          width: 0;
          height: 100%;
          top: 0;
          left: auto;
          padding-left: 0;
          z-index: 1;
          display: none;

          &.show {
            display: inline-flex;
          }

          .overflow,
          .we-loading-overflow {
            width: 268px;
            min-width: 268px;
            margin-right: 6px;
            background: rgba(var(--background-1-rgb), 0.9);
            font-size: 14px;
            color: var(--text-2);
            font-weight: $semibold;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            padding: 20px;

            .bold {
              color: var(--text-1);
              text-align: center;
              pointer-events: none;
            }
          }
        }

        .loadings {
          display: inline-flex;
          .we-loading-overflow {
            position: relative;
            opacity: 0;

            &.show {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
