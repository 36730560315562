<i18n>
{
  "en": {
    "initial-stage": "Initial stage: reports will be generated from here",
    "deal-stage": "Won stage: reports will be generated up to here",
    "show-more": "Show more",
    "lost": "Lost"
  },
  "pt-BR": {
    "initial-stage": "Estágio inicial: os relatórios serão gerados a partir daqui",
    "deal-stage": "Estágio de conversão: os relatórios serão gerados até aqui",
    "show-more": "Mostrar mais",
    "lost": "Abandonado"
  }
}
</i18n>

<template>
  <div class="we-pipeline-column">
    <div class="head">
      <div class="name">
        <we-icon-tooltip icon="flag-checkered" v-if="stage.is_the_first_stage">
          <template #text>
            {{ $t("initial-stage") }}
          </template>
        </we-icon-tooltip>
        <we-icon-tooltip icon="award" v-if="stage.is_the_win_stage">
          <template #text>
            {{ $t("deal-stage") }}
          </template>
        </we-icon-tooltip>
        <we-text-tooltip
          :delay="500"
          :text="stage.index === 9999 ? $t('lost') : stage.name"
        />
        <div class="number">{{ dealsNumber }}</div>
      </div>
      <span class="value">{{ getAmount() }}</span>
    </div>
    <container
      class="list-group"
      :animation-duration="0"
      @drag-start="showDragOver"
      @drag-end="hideDragOver"
      group-name="people"
      @drop="e => moveDeal(stage, e)"
      :get-child-payload="getCardPayload()"
      :scrollSensitivity="200"
      :drag-handle-selector="enableDragging"
      :disableScrollOverlapDetection="true"
    >
      <draggable class="draggable" v-for="deal in deals" :key="deal.id">
        <we-pipeline-card
          :deal="deal"
          :stage="stage"
          @rightClick="rightClick"
        />
      </draggable>
      <div class="show-more" v-if="lastPage > currentPage">
        <we-button
          @click="loadMore"
          :loading="loadingBtn"
          class="disabled fill"
          icon="chevron-down"
          iconPosition="right"
          :text="$t('show-more')"
        />
      </div>
    </container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  props: {
    stage: Object,
    pipeline: Object,
    parentLoading: Boolean,
    index: Number
  },
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      loading: false,
      dragovered: false,
      loadingBtn: false,
      timeInStage: {
        icon: "hourglass-start",
        hours: 0
      },
      currentPage: 1
    };
  },
  computed: {
    enableDragging() {
      return this.$vuetify.breakpoint.width > 768 ? "" : "none";
    },
    allStages() {
      return this.$store.getters.getStageDeals;
    },
    currentStage() {
      return this.allStages.find(e => e.id === this.stage.id);
    },
    lastPage() {
      return this.currentStage?.last_page;
    },
    dealsNumber() {
      if (this.pipelineFiltered) {
        return this.deals.length;
      }
      return this.currentStage?.total;
    },
    deals() {
      return this.currentStage ? this.currentStage?.data : [];
    },
    pipelineFiltered() {
      return this.$store.getters.getPipelineFiltered;
    }
  },
  methods: {
    ...mapActions([
      "stageDealsRequest",
      "stageDealsPageRequest",
      "updateDealStageRequest",
      "stageRequest",
      "pipelinesRequest"
    ]),
    rightClick({ event, deal }) {
      this.$emit("dealRightClick", { event, deal });
    },
    getAmount() {
      return this.pipeline?.sum_field.type === "number"
        ? this.currentStage?.amount
        : this.$options.filters.valueToReais(this.currentStage?.amount);
    },
    getCardPayload() {
      return index => {
        return this.deals[index];
      };
    },
    async doRequests() {
      this.addLoadingColumn();
      this.currentPage = 1;
      const payload = {
        id: this.stage.id,
        pipelineId: this.pipeline.id,
        page: 1
      };
      await this.stageDealsRequest(payload);
      this.removeLoadingColumn();
    },
    async moveDeal(stage, evt) {
      this.hideDragOver();
      if (
        evt.addedIndex != null &&
        evt.payload.pipeline_stage_id != this.stage.id
      ) {
        this.currentPage = 1;
        const stageLeftId = evt.payload.pipeline_stage_id;
        let deal = evt.payload;

        if (stage.index != 999) {
          deal.lost_reason = "";
        }

        deal.pipeline_stage_id = stage.id;
        deal.stage_name = stage.name;
        delete deal.products;
        delete deal.contacts;
        delete deal.user;
        delete deal.companies;
        delete deal.custom_fields;
        this.addLoadingColumn();
        await this.updateDealStageRequest(deal);
        this.$root.$emit("dealMoved", stageLeftId);
        await this.doRequests();
      }
    },
    showDragOver() {
      this.$emit("dragOver", true);
    },
    hideDragOver() {
      this.$emit("dragOver", false);
    },
    addLoadingColumn() {
      this.$emit("addLoading", this.index);
    },
    removeLoadingColumn() {
      this.$emit("removeLoading", this.index);
    },
    async loadMore() {
      this.loadingBtn = true;
      const payload = {
        id: this.stage.id,
        pipelineId: this.pipeline.id,
        page: this.currentPage + 1
      };
      await this.stageDealsPageRequest(payload);
      this.currentPage++;
      this.loadingBtn = false;
    },
    moveRequest(stageId) {
      if (this.stage.id === stageId) {
        this.doRequests();
      }
    }
  },
  async mounted() {
    await this.doRequests();

    this.$root.$on("dealMoved", this.moveRequest);
  },
  beforeDestroy() {
    this.$root.$off("dealMoved", this.moveRequest);
  }
};
</script>

<style lang="scss">
.we-pipeline-column {
  width: 256px;
  min-width: 256px;
  margin-right: 18px;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-flow: column;

  @include desktop {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--background-2);
  }

  @include mobile {
    padding-bottom: 150px;
  }

  &:hover {
    @include desktop {
      width: 268px;
      min-width: 268px;
      margin-right: 6px;
      padding-right: 6px;
      -ms-overflow-style: initial; /* Internet Explorer 10+ */
      scrollbar-width: initial; /* Firefox */

      &::-webkit-scrollbar {
        display: initial; /* Safari and Chrome */
      }
    }
  }

  &.dragovered {
    .dragover {
      display: flex;
    }
  }

  .we-loading-overflow {
    padding: 20px;
    max-width: 256px;
    height: 100%;
    top: 62px;
    left: 0;
  }

  .head {
    padding: 8.5px 0;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $bold;
    color: var(--text-2);
    width: 100%;
    position: sticky;
    top: 0;
    background: var(--background-2);
    z-index: 1;

    .we-icon-tooltip {
      margin-right: 6px;

      svg {
        height: 13px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      border-radius: 2px;
      background: var(--primary);
      bottom: 0;
      width: 100%;
      left: 0;
    }

    .name {
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 0;

      .we-icon-tooltip svg {
        margin-left: 0;
      }

      .we-text-tooltip {
        flex: 1;
      }

      .number {
        height: 20px;
        display: flex;
        align-items: center;
        padding: 0 6px;
        border-radius: 4px;
        font-size: 13px;
        font-weight: $semibold;
        background: var(--action);
        color: var(--text-2);
        margin-left: 4px;
      }
    }

    .value {
      color: var(--green);
      font-size: 14px;
      font-weight: $semibold;
      margin-left: 8px;

      .currency {
        font-size: 11px;
      }
    }
  }

  .list-group {
    height: 100%;
  }

  .show-more {
    min-height: 38px;
    margin-top: 8px;
    padding-bottom: 16px;
  }

  .draggable {
    &:first-child {
      margin-top: 12px;
    }
  }
}
</style>
